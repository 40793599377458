import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ElevationChart = ({ activityPoints, activities, downsampleInterval = 20, normalisedElevation = true }) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (Array.isArray(activityPoints) && activityPoints.length > 0) {
      // Step 1: Downsample the data first to reduce the number of points
      const downsampledData = activityPoints
        .filter((_, index) => index % downsampleInterval === 0); // Downsample the data

      let labels = [];
      let data = [];

      // Merge activities with points by matching on activity_id
      const mergedData = downsampledData.map(point => {
        const activity = activities.find(activity => activity.id === point.activity_id);

        return {
          ...point,
          activity_name: activity ? activity.name : 'Unknown Activity',
          activity_date: activity ? activity.date : 'Unknown Date'
        };
      });

      if (normalisedElevation) {
        // Group downsampled points by activity_id
        const groupedActivities = mergedData.reduce((acc, point) => {
          if (!acc[point.activity_id]) {
            acc[point.activity_id] = [];
          }
          acc[point.activity_id].push(point);
          return acc;
        }, {});

        // Normalize the elevation and adjust for each activity
        const adjustedData = Object.values(groupedActivities).flatMap(activityPointsGroup => {
          const startingElevation = activityPointsGroup[0]?.ele || 0; // Starting point becomes zero

          return activityPointsGroup.map(point => ({
            ...point,
            ele: point.ele - startingElevation, // Adjust elevation by subtracting the minimum elevation
          }));
        });

        const overallMinElevation = Math.min(...adjustedData.map(point => point.ele));

        // Add the absolute value of the overall minimum elevation to all points
        const normalisedData = adjustedData.map(point => ({
          ...point,
          ele: point.ele - overallMinElevation + 1 // Make all points above zero
        }));

        labels = normalisedData.map(point => point.activity_id);
        data = normalisedData.map(point => point.ele); // Adjusted elevation data
      } else {
        const overallMinElevation = Math.min(...mergedData.map(point => point.ele));
        const elevationAdjustment = overallMinElevation < 0 ? overallMinElevation * -1 : 0;

        labels = mergedData.map(point => point.activity_id);
        data = mergedData.map(point => point.ele + elevationAdjustment); // Data fix for below zero elevations
      }

      // Set chart data with updated labels and data
      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Elevation Profile',
            data: data,
            fill: 'origin',
            borderColor: 'rgba(140, 132, 120, 0.5)',
            borderWidth: 1,
            backgroundColor: 'rgba(140, 132, 120, 0.1)',
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 5,
            meta: mergedData, // Tooltip access to activity_name and activity_date
          },
        ],
      });
    }
  }, [activityPoints, activities, downsampleInterval, normalisedElevation]);

  return (
    <div className="stats-chart">
      {chartData && chartData.datasets && chartData.datasets.length > 0 ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            scales: {
              x: {
                display: false,
              },
              y: {
                title: {
                  display: true,
                  text: 'Elevation (m)',
                  font: {
                    family: 'Segoe UI',
                    size: 14,
                  },
                },
                ticks: {
                  font: {
                    family: 'Segoe UI',
                    size: 12,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
                backgroundColor: '#17354C',
                titleFont: {
                  family: 'Segoe UI',
                  size: 14,
                  weight: 'bold',
                  color: '#F6F9FC',
                },
                bodyFont: {
                  family: 'Segoe UI',
                  size: 14,
                  color: '#F6F9FC',
                },
                padding: 10,
                callbacks: {
                  title: (tooltipItem) => {
                    const dataset = tooltipItem[0].dataset;
                    const index = tooltipItem[0].dataIndex;
                    const date = dataset.meta[index].activity_date;
                    const activityName = dataset.meta[index].activity_name;
                    return date ? `${new Date(date).toLocaleDateString('en-NZ')} - ${activityName}` : 'No date available';
                  },
                  label: (tooltipItems) => {
                    return `  ${tooltipItems.formattedValue} m`;
                  },
                  labelColor: () => {
                    return {
                      borderColor: '#BEBBAA',
                      backgroundColor: '#BEBBAA',
                    };
                  },
                },
                titleAlign: 'left',
              },
            },
          }}
        />
      ) : (
        console.debug('No elevation data available.')
      )}
    </div>
  );
};

export default ElevationChart;
