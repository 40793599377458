import React from 'react';
import { IconData, IconAnalytics, IconDigital, } from './Icons';


const Services = () => (
  <section id="services" className="content">
    <div className='services'>
      <div className='services-container services-odd'>
        <div className='services-content'>
          <div className='div-spacer-medium'>&nbsp;</div>
          <div className='services-content-title'>
              <div className='svg-icon-services'>
                <IconData width={60} height={60} scale={0.05} translatex={-4} translatey={12} strokeWidth={10} className={'icon-services'} />
              </div>
              <h2>Data</h2>
          </div>
          <div className='services-content-detail'>
            <h3>Data Strategy</h3>
              Crafting a tailored data strategy that aligns with your business goals, ensuring your data practices drive growth, informed decision-making, and continuous innovation.
          </div>
          <div className='services-content-detail'>
            <h3>Data Governance</h3>
              Developing and implementing data governance frameworks and policies to safeguard data quality, enhance security, and maintain regulatory compliance.
          </div>
          <div className='services-content-detail'>
            <h3>Data Architecture</h3>
              Designing and implementing scalable data architectures that adapt to your growing business needs, ensuring efficiency and flexibility as data demands increase.
          </div>
        </div>
        <div className='div-spacer-medium'>&nbsp;</div>
      </div>
      <div className='services-container services-even'>
      <div className='services-content'>
        <div className='div-spacer-medium'>&nbsp;</div>
        <div className='services-content-title'>
            <div className='svg-icon-services svg-icon-services-padded'>
              <IconAnalytics width={60} height={60} scale={0.6} translatex={0} translatey={0} strokeWidth={0} className={'icon-services'} />
            </div>
            <h2>Analytics</h2>
        </div>
        <div className='services-content-detail'>
          <h3>Business Intelligence and Reporting</h3>
            Implementing BI tools like Power BI, automating reporting processes, and developing dashboards that deliver actionable insights to drive decision-making.
        </div>
        <div className='services-content-detail'>
          <h3>Storytelling with Insights</h3>
            Turning complex data into compelling narratives that resonate with stakeholders, helping you communicate key insights clearly and drive action.
        </div>
        <div className='services-content-detail'>
          <h3>Analytics Platform Development</h3>
            Designing and deploying analytics platforms that integrate seamlessly with your existing systems, providing a unified view of your data for better analysis and decision-making.
        </div>
        </div>
        <div className='div-spacer-medium'>&nbsp;</div>
      </div>
      <div className='services-container services-odd'>
        <div className='services-content'>
          <div className='div-spacer-medium'>&nbsp;</div>
          <div className='services-content-title'>
            <div className='svg-icon-services svg-icon-services-padded'>
              <IconDigital width={60} height={60} scale={7.2} translatex={0} translatey={0} strokeWidth={0.05} className={'icon-services'} />
            </div>
            <h2>Digital</h2>
        </div>
          <div className='services-content-detail'>
            <h3>Digital Strategy and Transformation</h3>
              Creating and executing digital strategies that modernise your operations and enhance your digital presence, ensuring alignment with business goals.
          </div>
          <div className='services-content-detail'>
            <h3>Technology Modernisation</h3>
              Updating legacy systems and processes, introducing modern technologies to boost performance, scalability, and future-proof your business.
          </div>
          <div className='services-content-detail'>
            <h3>Fractional CTO Services</h3>
              Offering strategic technology leadership on a part-time or project basis, providing expertise in digital strategy, technology roadmaps, and innovation to drive business growth.
          </div>
        </div>
        <div className='div-spacer-medium'>&nbsp;</div>
      </div>
    </div>




  </section>
);

export default Services;
