import React from 'react';
import Hero from './Hero';
import Services from './Services';

const Home = () => (
  <section id="home" className="content">
    <div className='content-header'></div>
    <Hero />
    <div className='welcome'>
      <div className='welcome-text-box'>
        <div className='welcome-text'>
          <b>Welcome to Blue Spoke</b>, 
          where over two decades of experience in data, analytics, and digital are brought together to help businesses of all sizes unlock the full potential of their data and overcome technology challenges.
          <br /><br />
          With experience working with large enterprises such as IAG, UBS, and the University of Auckland, as well as smaller businesses and startups, Blue Spoke delivers customised, results-driven solutions that connect complex technical strategies with real-world business outcomes. From developing data governance frameworks to driving digital transformation initiatives, Blue Spoke ensures that data and technology drive meaningful results for your business.          
          <br /><br />
          Whether the goal is to gain deeper insights from data, navigate the complexities of the digital world, or benefit from fractional CTO support, Blue Spoke offers flexible engagement options tailored to the unique needs of any business, no matter the size or scope.
          <br /><br />
          If you're keen to chat, please <a href='mailto:mattw@bluespoke.nz'>get in touch</a>.
        </div>
      </div>
      <div className='welcome-image'>
        <picture>
          <source srcSet="/images/LakeGarda.webp" type="image/webp" />
          <source srcSet="/images/LakeGarda.jpg" type="image/jpg" />
          <img src="/images/LakeGarda.jpg" alt="Lake Garda" className="welcome-img" />
        </picture>

      </div>
    </div>
    <Services />
  </section>
);

export default Home;
