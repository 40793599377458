import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation(); // Get current URL path

  // Function to check if the path matches the active tab
  const isActive = (path) => location.pathname === path;

  const handleDropdownClick = (event) => {
    event.preventDefault();
    setDropdownOpen(!isDropdownOpen); // Toggle dropdown on click
  };

  const handleOptionClick = () => {
    setDropdownOpen(false); // Close dropdown after clicking an option
  };

  return (
    <header className="page-header">
      <picture>
        <source srcSet="/images/blue_spoke_logo.webp" type="image/webp" />
        <source srcSet="/images/blue_spoke_logo.png" type="image/png" />
        <img 
        src="/images/blue_spoke_logo.png" 
        className='img-header-logo' 
        alt="Blue Spoke Logo"
        style={{ cursor: 'pointer' }} 
        onClick={() => window.location.href = '/'} // Navigate to home
      />
      </picture>


      <ul className="horizontal-list">
        <li>
          <Link
            to="/"
            className={isActive('/') ? 'active' : ''}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={isActive('/about') ? 'active' : ''}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className={isActive('/contact') ? 'active' : ''}
          >
            Contact
          </Link>
        </li>
        <li className="dropdown">
          <Link
            to="/velovino"
            className={location.pathname.startsWith('/velovino') ? 'active' : ''}
            onClick={handleDropdownClick} // Handle dropdown toggle
          >
            Velo Vino
          </Link>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link
                  to="/velovino"
                  className={isActive('/velovino') ? 'active' : ''}
                  onClick={handleOptionClick} // Close dropdown after selecting
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/velovino/journey"
                  className={isActive('/velovino/journey') ? 'active' : ''}
                  onClick={handleOptionClick} // Close dropdown after selecting
                >
                  The Journey
                </Link>
              </li>
              <li>
                <Link
                  to="/velovino/activities"
                  className={isActive('/velovino/activities') ? 'active' : ''}
                  onClick={handleOptionClick} // Close dropdown after selecting
                >
                  The Activities
                </Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </header>
  );
};

export default Header;
