import React from 'react';

const IconSvg = ({ width = 100, height = 100, scale = 1, translatex = 0, translatey = 0, strokeWidth = 1, className = 'icon', paths = [] }) => {
  const viewBox = `0 0 ${width} ${height}`;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g transform={`translate(${translatex} ${translatey}) scale(${scale}, ${scale})`}>
        {paths.map((pathData, index) => (
          <path 
            key={index}
            d={pathData.d}
            fill={pathData.color || ''}
            fillRule='evenodd'
            className={className || 'stat-icon'}
            strokeWidth={strokeWidth || 1}
            style={{
              opacity: pathData.opacity || 1,          
            }}
          />
        ))}
      </g>
    </svg>
  );
};

export default IconSvg;
