import axios from 'axios';

export const logEvent = (eventType, eventDetails) => {
  const logBaseUrl = process.env.REACT_APP_LOG_BASE_URL;


  const timestamp = new Date().toISOString();
  const eventData = { eventType, eventDetails, timestamp };

  axios.post(`${logBaseUrl}/event`, eventData)
    .then(response => {
      //console.log('Event logged:', response.data);
      console.log('')
    })
    .catch(error => {
      console.error('Error logging event:', error);
    });
};
