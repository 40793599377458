import React from 'react';
import { IconBike, IconHeartRate, IconSpeed, IconTemp, IconElevation, IconDuration } from './Icons';

const JourneyStats = () => {
  
  const stats01 = [
    { id: 'statsDistance', text: '2,523 km', desc: 'cycled', scale: 0.8, translatex: 31.5, translatey: -10, strokeWidth: 0.8, className : 'stat-icon', IconComponent: IconBike },
    { id: 'statsDuration', text: '4.5 months', desc: '', scale: 0.65, translatex: 38, translatey: -8, strokeWidth: 0, className : 'stat-icon', IconComponent: IconDuration },
    { id: 'statsElevation', text: '12,795 m', desc: 'climbed', scale: 0.6, translatex: 39, translatey: -8, strokeWidth: 1.5, className : 'stat-icon', IconComponent: IconElevation },
  ];

  const stats02 = [
    { id: 'statsMaxHR', text: '184 bpm', desc: 'max hr', scale: 0.55, translatex: 40.7, translatey: -1, strokeWidth: 2, className : 'stat-icon', IconComponent: IconHeartRate },
    { id: 'statsMaxSpeed', text: '55.6 km/h', desc: 'max speed', scale: 0.6, translatex: 38.5, translatey: -4, strokeWidth: 1, className : 'stat-icon', IconComponent: IconSpeed },
    { id: 'statsMaxTemp', text: '40 °C', desc: 'max temp', scale: 0.5, translatex: 40, translatey: 1, strokeWidth: 1, className : 'stat-icon', IconComponent: IconTemp },
  ];

  return (
    <>
    <div className="stats-row">
      <div className='stats-group'>
        {stats01.map((stat) => (
          <div key={stat.id} className="column-centred">
            <div className="stats-image-medium">
              <div className='svg-icon svg-icon-medium'>
                <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
              </div>
              <div className="stat-text-medium stat-text-medium-value">{stat.text}</div>
              <div className="stat-text-medium">{stat.desc}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='stats-group'>
        {stats02.map((stat) => (
          <div key={stat.id} className="column-centred">
            <div className="stats-image-medium">
              <div className='svg-icon svg-icon-medium'>
                <stat.IconComponent width={stat.width || 135} height={stat.height || 50} scale={stat.scale || 1} translatex={stat.translatex || 0} translatey={stat.translatey || 0} strokeWidth={stat.strokeWidth || 1} className={stat.className} />
              </div>
              <div className="stat-text-medium stat-text-medium-value">{stat.text}</div>
              <div className="stat-text-medium">{stat.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};
  
export default JourneyStats;
