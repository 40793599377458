import React from 'react';

const Contact = () => (
  <section id="contact" className="content">
    <div className='content-header'>
    </div>
    <div className='content-header-text'>
      Contact
    </div>
    <div className='div-spacer-large'></div>
    <div className='contact-us'>To get in touch, please contact me at <a href='mailto:mattw@bluespoke.nz'>mattw@bluespoke.nz</a>
      <div className='div-spacer-large'></div>
      <div>
        <picture>
          <source srcSet="/images/MangawhaiBeach.webp" type="image/webp" />
          <source srcSet="/images/MangawhaiBeach.png" type="image/png" />
          <img src="/images/MangawhaiBeach.png" alt="Mangawhai Beach" className="contact-image" />
        </picture>
      </div>
    </div>
  </section>
);

export default Contact;
