import React from 'react';
import { Link } from 'react-router-dom'; 
import { IconMap, IconMountain } from './Icons';

const VeloVino = () => {
  return (
    <section id="velovino" className="content">
      <div className='content-header'></div>
      <div className='content-header-text'>
        Velo Vino
      </div>
      <div className="box-container velo-vino-text">
        <div className='velo-vino-text-details'>
          Bringing together my love of travel, cycling, and data, this website started out as an idea while on an amazing journey in Europe. With more than <b>180,000 data points</b> to bring to life, I started with Shiny for R then refactored into React and Node.js and expanded the scope to cover my 
          <Link to="/about" className='link-text'> career move</Link> to 
          <Link to="/" className='link-text'> consulting services</Link>.
          <br /><br />
          Velo Vino - the name we came up with for our trip, a bit French 
          &nbsp;
          <picture>
                <source srcSet="/icons/Velo.webp" type="image/webp" />
                <source srcSet="/icons/Velo.png" type="image/png" />
                <img src='/icons/Velo.png' alt="Velo" className='emoji-image'></img>
              </picture>
          , a bit Italian
          &nbsp;
          <picture>
                <source srcSet="/icons/Vino.webp" type="image/webp" />
                <source srcSet="/icons/Vino.png" type="image/png" />
                <img src='/icons/Vino.png' alt="Vino" className='emoji-image'></img>
              </picture> and a lot of fun for a couple of Kiwis. 
          In May 2023, we began our cycling adventure in Paris, 4.5 months later we rolled our bikes into Rome. We cycled over <b>2,523 kilometres</b> and conquered <b>12,795 metres</b> of vertical climbs.
          Along the way, we occasionally hopped on trains or ferries to explore new regions or speed up our journey. And while we didn’t get a single puncture, there was an exploding tire, but that's a story for another day!
          <br /><br />         
          Here, you can explore our <Link to={{ pathname: "/velovino/journey" }} className='link-text'>full journey</Link> across Europe 
          or dive into the details of each <Link to="/velovino/activities" className='link-text'>daily ride</Link>. 
          For more photos and stories, take a look at our <a href='https://www.instagram.com/velovino.nz/' target='_blank' rel='noreferrer' >Instagram</a>.
          <br />
          <a href='https://www.instagram.com/velovino.nz/' target='_blank' rel='noreferrer' >
              <picture>
                <source srcSet="/images/instagram_logo.webp" type="image/webp" />
                <source srcSet="/images/instagram_logo.png" type="image/png" />
                <img src="/images/instagram_logo.png" alt="Instagram - Velo Vino" className='instagram-logo' />
              </picture>
          </a>
        </div>
        <div className='dolomiti'>
          <picture>
            <source srcSet="/images/dolomiti.webp" type="image/webp" />
            <source srcSet="/images/dolomiti.jpg" type="image/jpg" />
            <img src="/images/dolomiti.jpg" alt="The Dolomites" className="velo-vino-image" />
          </picture>
        </div>
      </div>
      <div className="box-container">
        <div className="box"
          style={{ cursor: 'pointer' }} 
        >
          <Link 
              to={{
                pathname: "/velovino/journey",
                state: { from: 'velovino' } 
              }}
              className="velo-vino-container"
          > 
            <div className='velo-vino-box'>
              <div className='velo-vino-box-text'>
              <IconMap width={60} height={60} scale={0.6} translatex={0} translatey={0} strokeWidth={0} className={'icon-services'} />
              </div>
              <div className='velo-vino-box-text'>
              See our journey 
              <br />across Europe.
              </div>
            </div>
            <picture>
              <source srcSet="/images/journey.webp" type="image/webp" />
              <source srcSet="/images/journey.jpg" type="image/jpg" />
              <img src="/images/journey.png" alt="Cycle Route Map" className="velo-vino-image" />        
            </picture>
        </Link>
        </div>
        <div className="box"
          style={{ cursor: 'pointer' }} 
        >
          <Link to="/velovino/activities" className="velo-vino-container"> 
            <div className='velo-vino-box'>
              <div className='velo-vino-box-text'>
                <IconMountain width={60} height={60} scale={0.6} translatex={0} translatey={0} strokeWidth={0} className={'icon-services'} />
              </div>
              <div className='velo-vino-box-text'>
                View our daily
                <br />cycling activities.
              </div>
            </div>
            <picture>
              <source srcSet="/images/activities.webp" type="image/webp" />
              <source srcSet="/images/activities.jpg" type="image/jpg" />
              <img src="/images/activities.jpg" alt="Cycle Activities" className="velo-vino-image" />        
            </picture>
          </Link>
        </div>
      </div>    
    </section>
  )
};

export default VeloVino;
