import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import ElevationChart from './ElevationChart';
import JourneyStats from './JourneyStats';
import MapRenderer from './MapRenderer';
import DistanceChart from './DistanceChart'; 
import L from 'leaflet';
import BackButton from './BackButton';

const Journey = () => {
  const location = useLocation();  // Get location object
  const searchParams = new URLSearchParams(location.search);
  const showBackButton = searchParams.get('showBack');
  console.log(showBackButton);

  const [activities, setActivities] = useState([]);
  const [activityPoints, setActivityPoints] = useState([]);
  const [transports, setTransports] = useState([]);
  const [transportActivities, setTransportActivities] = useState([]);
  const [garminStats, setGarminStats] = useState([]);

  useEffect(() => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    console.log(location);  // Log the whole location object to inspect it


    // Fetch activities from the API
    fetch(`${apiBaseUrl}/activities`)
      .then(response => response.json())
      .then(data => {
        setActivities(data);
      })
      .catch(error => console.error('Error fetching activities:', error));

    // Fetch activity points from the API
    fetch(`${apiBaseUrl}/activity-points`)
      .then(response => response.json())
      .then(data => {
        setActivityPoints(data);
      })
      .catch(error => console.error('Error fetching activity points:', error));

    // Fetch transports from the API
    fetch(`${apiBaseUrl}/transport`)
      .then(response => response.json())
      .then(data => {
        setTransports(data);
      })
      .catch(error => console.error('Error fetching transports:', error));

    // Fetch transport activities from the API
    fetch(`${apiBaseUrl}/transport-points`)
      .then(response => response.json())
      .then(data => {
        setTransportActivities(data);
      })
      .catch(error => console.error('Error fetching transport activities:', error));

    // Fetch Garmin stats from the API
    fetch(`${apiBaseUrl}/garmin-stats`)
      .then(response => response.json())
      .then(data => {
        setGarminStats(data);
      })
      .catch(error => console.error('Error fetching Garmin stats:', error));
  }, []);

  // Merge Garmin stats with activities
  const mergedActivities = activities.map(activity => {
    const stats = garminStats.find(stat => stat.ID === activity.id);
    return {
      ...activity,
      stats: stats || {}, // Add stats data to each activity
    };
  });

  // Check if data is loaded before processing
  if (!activities.length || !activityPoints.length || !transports.length || !transportActivities.length) {
    return <section id="journey" className="content">
      <div className='content-header'></div>
    
      <div className='loading'>
        <div className='loading-text'>Loading...</div>
      </div>
     </section>;
  }

  // Combine activities with their respective points
  const activityData = activities.map(activity => {
    const points = activityPoints
      .filter(point => point.activity_id === activity.id && point.lat !== undefined && point.lon !== undefined)
      .map(point => {
        return [point.lat, point.lon];
      });

    return { ...activity, points };
  });

  // Group activities by 'group' and select the first activity in each group
  const groupedActivities = Object.values(activityData.reduce((groups, activity) => {
    const groupKey = activity.group;
    if (!groups[groupKey]) {
      groups[groupKey] = activity;
    }

    return groups;
  }, {}));

  // Combine transports with their respective activities
  const transportData = transports.map(transport => {
    const points = transportActivities
      .filter(activity => activity.transport_id === transport.id && activity.lat !== undefined && activity.lon !== undefined)
      .map(activity => {
        return [activity.lat, activity.lon];
      });

    return { ...transport, points };
  });

  // Convert icon paths to L.Icon objects
  const createIcon = (iconUrl, iconSize = [32, 24], iconAnchor = [16, 24], popupAnchor = [0, -15]) => {
    return new L.Icon({
      iconUrl,
      iconSize,
      iconAnchor,
      popupAnchor
    });
  };

  // Prepare markers for the map
  const markers = [
    ...groupedActivities.map(activity => ({
      position: activity.points[0],
      icon: createIcon(`/icons/marker_bike_blue.png`, [48, 48], [24, 48], [0, -35]),
      name: activity.group,
      imageUrl: `/images/popup_bike.png`
    })),
    ...transportData.map(transport => ({
      position: transport.points[0],
      icon: createIcon(`/icons/marker_${transport.type}.png`),
      name: `${transport.origin} - ${transport.destination}`,
      imageUrl: `/images/popup_${transport.type}.png`,
    }))
  ];

  // Prepare polyline groups with custom styles for activities and transports
  const polylineGroups = [
    ...Object.entries(activityData.reduce((groups, activity) => {
      const groupKey = activity.group;
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(...activity.points);
      return groups;
    }, {})).map(([groupKey, points], idx) => ({
      points,
      color: '#205CA4',
      weight: 3, 
      opacity: 0.8,
    })),
    ...transports.map(transport => {
      const transportPoints = transportActivities
        .filter(activity => activity.transport_id === transport.id && activity.lat !== undefined && activity.lon !== undefined)
        .map(activity => [activity.lat, activity.lon]);

      if (transportPoints.length > 0) {
        return {
          points: transportPoints,
          color: transport.type === 'train' ? '#BEBBAA' : transport.type === 'ferry' ? '#CCD6E2' : 'black', // Custom colors based on transport type
          weight: 2,
          opacity: 0.4,
        };
      }
      return null;
    }).filter(polyline => polyline !== null),
  ];

  // Conditionally render the BackButton based on location state
  
  
  return (
    <section id="journey" className="content">
      <div className='content-header'></div>
      <div className='content-header-text velo-vino-header'>
        <div className='velo-vino-header'>The Journey</div>
        {true && <BackButton />}
      </div>
      <div className='div-spacer-medium'>&nbsp;</div>
      <div className='journey-stats'>
        <JourneyStats />
      </div>
      <div className='div-spacer-medium'>&nbsp;</div>
      <div className="journey-map">
        <MapRenderer
          markers={markers}
          polylineGroups={polylineGroups}
          popupContent={(item) => (
            <>
              <strong>{item.name}</strong><br />
            </>
          )}
        />
      </div>
      <div className='chart-display'>
        <h2>Elevation Profile</h2>
        <ElevationChart 
          activityPoints={activityPoints} 
          activities={activities}
          downsampleInterval={20} 
          normalisedElevation={false}
        />
      </div>
      <div className='chart-display'>
        <h2>Daily Cycled Distances</h2>
        <DistanceChart activities={mergedActivities} /> {/* Add the new chart here */}
      </div>
    </section>
  );
};

export default Journey;
