import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconBackButton } from './Icons';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      // Go back in history if possible
      navigate(-1);
    } else {
      // If no history, navigate to the home page
      navigate('/');
    }
  };

  return (
    <>
        <div className="back-button" onClick={handleBackClick}>
            <div className="back-button-tooltip-container">
                <IconBackButton width={75} height={60} scale={0.15} translatex={0} translatey={0} strokeWidth={0} className={'icon-back-button'} />
                <span className="back-button-tooltip-text">Back</span>
            </div>
        </div>
    </>
);
};

export default BackButton;
