import React, { useRef, useEffect, useState } from 'react';

const ActivitySelection = ({
  activities,
  selectedActivities,
  setSelectedActivities,
  handleActivityClick,
  isMobile,
  scrollToActivity,
  setScrollToActivityFalse
}) => {
  const selectedActivityRef = useRef(null);
  const [selectedGroup, setSelectedGroup] = useState('All');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Find unique groups from the activities data
  const activityGroups = ['All', ...new Set(activities.map(activity => activity.group))];

  useEffect(() => {
    if (scrollToActivity && selectedActivityRef.current) {
      selectedActivityRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setScrollToActivityFalse();
    }
  }, [scrollToActivity, setScrollToActivityFalse, selectedActivities]);

  // Handle group selection and activity filtering
  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setDropdownOpen(false);

    if (group === 'All') {
      setSelectedActivities([activities[0].id]);
    } else {
      const activitiesInGroup = activities
        .filter(activity => activity.group === group)
        .map(activity => activity.id);
      setSelectedActivities(activitiesInGroup);
    }
  };

  // Filter activities based on selected group
  const filteredActivities = selectedGroup === 'All'
    ? activities
    : activities.filter(activity => activity.group === selectedGroup);

  return (
    <div className="activity-selection">
      <div className="activity-selection-title">Continuous Cycling Segment:</div>
      
      {/* Custom dropdown */}
      <div className='activity-group'>
        <div className="custom-dropdown">
          <div
            className="dropdown-selected"
            onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown visibility
          >
            {selectedGroup} <span className="arrow">{dropdownOpen ? '▲' : '▼'}</span>
          </div>
          {dropdownOpen && (
            <div className="dropdown-options">
              {activityGroups.map(group => (
                <div
                  key={group}
                  className={`dropdown-item ${selectedGroup === group ? 'selected' : ''}`}
                  onClick={() => handleGroupSelect(group)} // Select group on click
                >
                  {group}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="activity-selection-title">Daily Activity:</div>
      <div className="scrollable-list">
        {filteredActivities.map((activity, index) => (
          <div
            key={activity.id}
            ref={selectedActivities.includes(activity.id) ? selectedActivityRef : null}
            className={`activity-item ${selectedActivities.includes(activity.id) ? 'selected' : ''}`}
            onClick={(event) => handleActivityClick(event, activity.id, index, filteredActivities)} // Pass Shift-click logic
          >
            {isMobile ? (
              <>
                <input
                  type="checkbox"
                  checked={selectedActivities.includes(activity.id)}
                  readOnly
                />
                &nbsp;
                <label>{activity.displayName}</label>
              </>
            ) : (
              <span>{activity.displayName}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivitySelection;
