import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DistanceChart = ({ activities }) => {
  const [chartData, setChartData] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null); // Store data for clicked tooltip
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Detect if the user is on a mobile device
    const checkIfMobile = () => {
      const mobile = window.innerWidth <= 768; // Example breakpoint for mobile
      setIsMobile(mobile);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(activities) && activities.length > 0) {
      // Get activity names (or IDs) and distances from Garmin stats
      const labels = activities.map((activity) => activity.name || `Activity ${activity.id}`);
      const distances = activities.map((activity) => parseFloat(activity.stats.Distance) || 0); // Get distance from stats

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Distance (km)',
            data: distances,
            backgroundColor: 'rgba(204, 214, 226, 0.6)',
            borderColor: 'rgba(204, 214, 226, 1)',
            borderWidth: 1,
          },
        ],
      });
    }
  }, [activities]);

  const handleClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const selectedActivity = activities[index];

      if (isMobile) {
        // On mobile: First click shows tooltip, second click navigates to activity
        if (!tooltipVisible) {
          setTooltipVisible(true);
          setTooltipData(selectedActivity); // Store the selected activity
        } else {
          // Navigate to activity page on second click on mobile
          // navigate(`/velovino/activities/${selectedActivity.id}`); disable for now
          // setTooltipVisible(false); // Reset tooltip visibility
        }
      } else {
        // On desktop: Immediately navigate to activity page
        navigate(`/velovino/activities/${selectedActivity.id}`);
      }
    }
  };

  return (
    <div className="stats-chart">
      {chartData && chartData.datasets && chartData.datasets.length > 0 ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Distance (km)',
                  font: {
                    family: 'Segoe UI',
                    size: 14,
                  },
                },
                ticks: {
                    font: {
                      family: 'Segoe UI',
                      size: 12,
                    },
                  },
              },
              x: {
                display: false,
              },
            },
            plugins: {
              title: {
                display: false,
              },
                legend: {
                    display: false,
              },
              tooltip: {
                enabled: isMobile ? tooltipVisible : true, // Show tooltip only on first click on mobile
                backgroundColor: '#17354C',
                titleFont: {
                family: 'Segoe UI',
                size: 14,
                weight: 'bold',
                color: '#F6F9FC',
              },
              bodyFont: {
                  family: 'Segoe UI',
                  size: 14,
                  color: '#F6F9FC',
              },
              padding: 15,
              callbacks: {
                  title: (tooltipItems) => {
                      return `${tooltipItems[0].label}`;
                  },
                  label: (tooltipItem) => {
                      const distance = tooltipItem.raw.toFixed(2); // Format the distance value to 2 decimal places
                      return `  ${distance} km`; // Custom label text
                  },
                  labelColor: () => {
                      return {
                      borderColor: '#CCD6E2',
                      backgroundColor: '#CCD6E2',
                      
                      };                  
                  },                  
              },
                titleAlign: 'left',
                },
            },            
            onHover: (event, chartElement) => {
              const target = event.native ? event.native.target : event.target;
              // If hovering over a chart element, change the cursor to pointer
              target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },            
            onClick: (event, elements) => handleClick(event, elements), // Add onClick handler
          }}
        />
      ) : (
        <p>No data available for the chart.</p>
      )}
    </div>
  );
};

export default DistanceChart;
