import React from 'react';

const Hero = () => {
  return (
    <div className="hero-container">
      <picture>
        <source srcSet="/images/blue_spoke_hero.webp" type="image/webp" />
        <source srcSet="/images/blue_spoke_hero.png" type="image/png" />
        <img src="/images/blue_spoke_hero.png" alt="Blue Spoke Consulting" className="hero-image" />
      </picture>
      <div className="hero-text">
        <h1>Expert Consulting in <br />Data, Analytics, and Digital</h1>
      </div>
    </div>
  );
};


export default Hero;
